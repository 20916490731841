import type { ApiNotification } from '~/api-core/Notifications/FetchNotifications'

function validateNull(candidate: any): candidate is null {
  return candidate === null
}
function validateObject(candidate: any): candidate is Record<string, unknown> {
  return typeof candidate === 'object'
}
function validateString(candidate: any): candidate is string {
  return typeof candidate === 'string'
}
function validateNumber(candidate: any): candidate is number {
  return typeof candidate === 'number'
}

/**
 * Used to quickly evluate if an entity is actually a notification. This is ment for socket data above all since I don't trust it that much right now, we'll see how it fairs in the future.
 *
 * @description Not quite sure we'll keep this one arround.
 * @param {any} candidate - A candidate entity to evaluate as a notification.
 * @returns {boolean} True if the object is close enough to a notification, false if it's not.
 */
export function notificationIsValid(
  candidate: any,
): candidate is ApiNotification {
  return (
    validateObject(candidate) &&
    validateObject(candidate.context) &&
    (validateNull(candidate.datetime_seen) ||
      validateString(candidate.datetime_seen)) &&
    validateString(candidate.datetime_sent) &&
    validateNumber(candidate.id) &&
    validateString(candidate.kind) &&
    validateNumber(candidate.receiver) &&
    (validateNull(candidate.submission) || validateNumber(candidate.submission))
  )
}
