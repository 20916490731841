import type { ApiNotification } from '~/api-core/Notifications/FetchNotifications'
import type { Notification } from '~/types/notification-v2'

export function buildNotificationFromRawCoreData(
  rawNotification: ApiNotification,
): Notification {
  return {
    ...rawNotification,
    dateSeen: rawNotification.datetime_seen
      ? new Date(rawNotification.datetime_seen)
      : null,
    dateSent: new Date(rawNotification.datetime_sent),
  }
}
